import React from 'react';
import AOS from 'aos';
AOS.init();



const hero = () => {
	return(
		<div className="container">
			<div className="row">
				<div className="col-md-12 text-center">
	                <h2>My Resume</h2>
	                <div className="space-border"></div>
	            </div>
			</div>
			<div className="row gh-5">

				<div className="col-lg-8"
                    data-aos="fade-up"
                        data-aos-once="true"
                    >
                    <div className="p-4">
                        <h3 className="s_border">Experiences</h3>
                        <ul className="d_timeline">
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2023 - now</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Entrepreneur</span>
                                	<span className="d_company">Scripters</span>
                                    As the founder of Scripters, I am an entrepreneur with a passion for creating innovative digital solutions.
                                    At Scripters, my goal is to create a company that is dedicated to helping our clients achieve their digital goals.
                                    As an entrepreneur, I am passionate about creating solutions that have a real impact on people's lives.
                                    I am excited to see what the future holds for Scripters.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2020 - 2022</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Senior Software Engineer / Team Leader</span>
                                	<span className="d_company">Omni Calculator</span>
                                    As a team leader in Omni Calculator, a leading online calculator platform, I was responsible for expanding the development team and establishing a new work environment that utilized agile practices. My role involved recruiting new developers, building and mentoring the team, and managing the development process to ensure high-quality output and timely delivery of projects.
                                    Throughout my time at Omni Calculator, I focused on ensuring that the team was always focused on delivering solutions that met the needs of the business. I leveraged my experience in TypeScript, React, and Node.js to create software that was both user-friendly and high-performing.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2015 - 2022</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Software Engineer / Tech Lead</span>
                                    <span className="d_company">MemoFriend</span>
                                    As a freelance developer I was responsible for planning and creating the entire architecture of the platform, as well as building two React applications with complex backends to meet the business's needs. My role involved collaborating closely with the client to identify and understand their requirements, and to develop a custom solution that met their unique needs.
                                    Throughout the project, I was responsible for designing the software architecture, coding the frontend and backend, testing the software, and ensuring that the platform met high-quality standards. I leveraged my extensive experience in React and Node.js to create user-friendly, high-performance, and scalable software applications that provided a seamless experience for users.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2017 - 2019</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Software Engineer / Senior Software Engineer</span>
                                	<span className="d_company">Schibsted Tech Poland</span>
                                    As a Software Engineer at Schibsted Tech Poland, I had the opportunity to work on a high-profile project creating a subscription management portal for Schibsted news services.
                                    I was involved in all aspects of the project, from requirements gathering and design to development, testing, and deployment. I took on a mentoring role within the team, providing guidance and support to less experienced developers, and I actively participated in code reviews and agile development processes.
                                    I learned how to effectively manage priorities, collaborate with team members, and deliver high-quality software on time.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2016 - 2017</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Software Engineer</span>
                                    <span className="d_company">VML Poland</span>
                                    As a software engineer at VML agency, I gained valuable experience developing complex applications using React and Node.js.
                                    One of my major achievements at VML agency was developing the backend for the "Męskie Granie" promotional website. This website was designed to promote a popular Polish music festival, and it required a high degree of technical complexity and performance. I was responsible for implementing the backend services, which involved integrating with third-party APIs and ensuring high availability and scalability.
                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2013 - 2016</h3>
                                <p className="d_timeline-text">
                                    <span className="d_title">Junior Software Engineer / Software Engineer</span>
                                    <span className="d_company">DreamLab Onet.pl</span>
                                    As a junior developer at DreamLab Onet.pl, I gained valuable experience developing and maintaining m.onet.pl, a popular Polish news and entertainment website, as well as Onet's mobile applications. During my time at the company, I honed my skills in web development, mobile app development, and troubleshooting, and I learned how to work in a fast-paced, dynamic environment.
                                    I worked closely with other developers, designers, and product managers to identify and solve technical challenges, and I actively participated in code reviews and agile development processes.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-lg-4"
                    data-aos="fade-up"
                        data-aos-once="true"
                    >
                    <div className="p-4">
                        <h3 className="s_border">Education</h3>
                        <ul className="d_timeline">
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2016 - 2018</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Master of Computer Science</span>
                                	<span className="d_company">Cracow University of Technology</span>

                                </p>
                            </li>
                            <li className="d_timeline-item">
                                <h3 className="d_timeline-title">2011 - 2015</h3>
                                <p className="d_timeline-text">
                                	<span className="d_title">Bachelor of Computer Science</span>
                                	<span className="d_company">Cracow University of Technology</span>

                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="spacer-double"></div>
			</div>
		</div>
	);
}

export default hero;
