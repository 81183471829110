import { Parallax } from "react-parallax";
import { Link } from "react-scroll";
import Navbar from '../layout/Navbar';
import Hero from '../section/hero';
import About from '../section/about';
import Blockquote from '../section/blockquote';
import Whatido from '../section/whatido';
import Resume from '../section/resume';
import Contact from '../section/contact';
import Footer from '../section/footer';
import Preloader from "../layout/Preloader";
import ScrollToTopBtn from '../layout/ScrollToTop';
import Particle from '../layout/Particle';
import { createGlobalStyle } from 'styled-components';
// import {useEffect} from "react";
// import ReactGA from 'react-ga4';

const mainBackground ="./img/background/bg-3.jpg";

const GlobalStyles = createGlobalStyle`
  .navbar-brand .imginit{
      display: block ;
    }
    .navbar-brand .imgsaly{
      display: none !important;
    }
`;

const Home = () => {
  // useEffect(() => {
  //   console.log('here');
  //   ReactGA.send({ hitType: "pageview", page: "/", title: 'Main page' });
  // }, []);

  return (
    <>
    <GlobalStyles/>
    <Preloader/>
    <div className="home">
      <header id="header-wrap">
        <Navbar />
      </header>

      {/* HERO */}
      <section id="hero-area" className="bg-bottom py-0">
        <Parallax bgImage={mainBackground} strength={200}>
        <Particle/>
        <Hero/>
        <Link smooth spy to="about">
          <span className="mouse transition" id="fly">
              <span className="scroll"></span>
          </span>
        </Link>
        </Parallax>
      </section>

      {/* ABOUT */}
      <section id="about" className="pb-0">
        <About/>
        <Blockquote/>
      </section>

      {/* What I DO */}
      <section id="whatido">
        <Whatido/>
      </section>

       {/*Gallery*/}
      {/*<section id="gallery">*/}
      {/*  <Gallery/>*/}
      {/*</section>*/}

      {/* Gallery */}
      <section id="resume" className="pb-0">
        <Resume/>
        {/*<Counter/>*/}
      </section>

      {/* contact */}
      <section id="contact" className="pb-0">
        <Contact/>
        <Footer/>
      </section>

      <div className="float-text">
          <div className="de_social-icons">
              <span className="buton" onClick={() => { window.location.href = 'https://www.linkedin.com/in/wojciech-falowski/'} }><i className="fa fa-linkedin fa-lg"></i></span>
              <span className="buton" onClick={() => { window.location.href = 'https://www.github.com/wfalowski/'} }><i className="fa fa-github fa-lg"></i></span>
              {/*<span className="buton"><i className="fa fa-twitter fa-lg"></i></span>*/}
          </div>
          <span>Follow Me</span>
      </div>
    </div>
    <ScrollToTopBtn />
    </>
  );
}

export default Home;
